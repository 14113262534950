
import {Component, Prop} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import {validationMixin} from 'vuelidate';
import {minLength, required} from 'vuelidate/lib/validators';
import AccountRepository from "@/api/repositories/AccountRepository";
import {maxLength} from "@/misc/CustomValidators";
import {MaxLengthValidation} from "@/enum/MaxLengthValidation.enum";
import {namespace} from "vuex-class";
import {AUTH_STORE_NAME, AuthStoreMutations} from "@/store/auth.store";
import {validateMinCharacter, validatePasswordContent} from "@/misc/ValidatePassword";

const AuthStore = namespace(AUTH_STORE_NAME);

@Component({
  components: {},
  mixins: [validationMixin],
  validations: {
    oldPassword: {
      required
    },
    newPassword: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(MaxLengthValidation.PASSWORD)
    },
  }
})
export default class ChangePasswordComponent extends mixins(ErrorMessageHandlerMixin) {
  @AuthStore.Mutation(AuthStoreMutations.SET_TOKEN)
  private setTokenAction!: (value: string) => void;

  @AuthStore.Mutation(AuthStoreMutations.SET_REFRESH_TOKEN)
  private setRefreshTokenAction!: (value: string) => void;

  /**
   * Is component loaded in modal dialog?
   */
  @Prop({default: true})
  public isModal!: boolean;

  private hasMinimumCharacters: boolean = false;
  private hasMinimumRequirements: boolean = false;
  private showSuggestions: boolean = false;

  /**
   * old password input field value
   * @private
   */
  public oldPassword: string = '';

  /**
   * new password input field value
   * @private
   */
  public newPassword: string = '';

  /**
   * flag that indicates if the old password field should be hidden
   * @private
   */
  private hideOldPassword = true;

  /**
   * flag that indicats if the new password field should be hidden
   * @private
   */
  private hideNewPassword = true;

  /**
   * State Bool for a Valid form
   * @private
   */
  private isValid = true;

  /**
   * State Bool for a Loading Indicator
   * @private
   */
  private isLoading = false;

  /**
   * validates if the password has correct requirements, checks
   * if the password has minimum length of six and if the there is at least one figure and special character
   * @param value
   * @private
   */
  private onValidatePassword(value: string) {
    this.hasMinimumCharacters = validateMinCharacter(value);
    this.hasMinimumRequirements = validatePasswordContent(value);
    this.triggerValidation('newPassword');
  }

  /**
   * is called when the user clicks on the save button, validates the inputs and tries to change the password of the
   * user
   * @private
   */
  private async saveData() {
    if(!this.hasMinimumCharacters || !this.hasMinimumRequirements) {
      return;
    }

    // validates the form and checks if every input was made correctly
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }

    try {
      // tries to change the password in the api
      this.isLoading = true;
      const response = await AccountRepository.changePassword(this.oldPassword!, this.newPassword!);
      const token = response.data['access_token'];
      const refreshToken = response.data['refresh_token'];
      this.setTokenAction(token);
      this.setRefreshTokenAction(refreshToken);

      // Show Success Message
      this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.PASSWORD_CHANGED_SUCCESS');

      // closes modal and resets loading button
      this.isLoading = false;
      this.dismiss(true);
    } catch (_) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.PASSWORD_CHANGED_FAILURE');
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * is called when the dialog should be dismissed, calls close dialog action
   * @param reload
   * @private
   */
  private dismiss(reload: boolean = false) {
    this.oldPassword = '';
    this.newPassword = '';
    this.$v.$reset();
    this.$emit('closeDialog', reload);
  }
}
